<template>
    <div>
        <el-card style="height: calc(100vh - 160px)">
            <div slot="header" class="header">会员中心</div>
            <div class="card-box">
                <div
                    class="money-item"
                    :class="{ 'money-item-selected': item.selected }"
                    v-for="(item, index) in vipList"
                    @click="handleSwichVipItem(index)"
                    :key="item.title"
                >
                    <p v-if="item.tips" class="card-tips">{{ item.tips }}</p>
                    <div class="title-1">{{ item.title }}</div>
                    <div class="title-2">
                        ¥
                        <span class="money-number">{{ item.price }}</span>
                        <span v-if="index && index !== vipList.length - 1">
                            /月
                        </span>
                    </div>
                    <div class="card-footer">{{ item.desc }}</div>

                    <img
                        class="vip-card-icon"
                        v-if="item.selected"
                        src="@/assets/img/vip/vip-card-active-icon.png"
                        alt=""
                    />
                </div>
            </div>
            <div class="vip-right-container">
                <div class="vip-right">
                    <div class="vip-right-header">VIP会员特权</div>
                    <div class="swiper-wrapper">
                        <div class="swiper-item">
                            <img
                                src="@/assets/img/vip/message.png"
                                alt=""
                                class="swiper-item-img"
                            />
                            <div>
                                <div class="swiper-item-title">消息推送</div>
                                <div class="swiper-item-desc">
                                    会员用户订阅的提单号，能够获得更加及时的消息推送
                                </div>
                            </div>
                        </div>
                        <div class="swiper-item">
                            <img
                                src="@/assets/img/vip/jogging.png"
                                alt=""
                                class="swiper-item-img"
                            />
                            <div>
                                <div class="swiper-item-title">提单订阅</div>
                                <div class="swiper-item-desc">
                                    每日拥有更多的提单订阅次数
                                </div>
                            </div>
                        </div>
                        <div class="swiper-item">
                            <img
                                src="@/assets/img/vip/revenue.png"
                                alt=""
                                class="swiper-item-img"
                            />
                            <div>
                                <div class="swiper-item-title">提单折扣</div>
                                <div class="swiper-item-desc">
                                    每日订阅超过一定数量，将获得更多的折扣
                                </div>
                            </div>
                        </div>
                        <div class="swiper-item">
                            <img
                                src="@/assets/img/vip/marketing.png"
                                alt=""
                                class="swiper-item-img"
                            />
                            <div>
                                <div class="swiper-item-title">免费宣传</div>
                                <div class="swiper-item-desc">
                                    在放舱网供应商目录免费展示公司信息
                                </div>
                            </div>
                        </div>
                        <div class="swiper-item">
                            <img
                                src="@/assets/img/vip/diversity.png"
                                alt=""
                                class="swiper-item-img"
                            />
                            <div>
                                <div class="swiper-item-title">归类指导</div>
                                <div class="swiper-item-desc">
                                    每周5次专业人士提供归类指导
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="vip-payment-container">
                <div style="display: flex">
                    <img
                        src="@/assets/img/vip/qrcode.png"
                        style="width: 120px; height: 120px; margin-left: 20px"
                        alt=""
                    />
                    <div>
                        <div class="vip-payment-time">支付倒计时:01:00</div>
                        <div style="margin-top: 20px; margin-left: 20px">
                            实付：
                            <span style="color: red">
                                <span style="font-size: 30px; font-weight: 700">
                                    {{ vipList[curIndex].total }}
                                </span>
                                元
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            curIndex: 0,
            vipList: [
                {
                    title: "7日会员",
                    price: 20,
                    desc: "用来临时解锁会员功能",
                    selected: true,
                    total: 20,
                },
                {
                    title: "30日会员",
                    price: 50,
                    desc: "拥有更多的每日订阅次数",
                    selected: false,
                    total: 50,
                },
                {
                    title: "90日会员",
                    price: 45,
                    desc: "更便宜的会员价格",
                    selected: false,
                    total: 135,
                },
                {
                    title: "180日会员",
                    price: 40,
                    desc: "更便宜的会员价格",
                    tips: "赠送10天会员",
                    selected: false,
                    total: 240,
                },
                {
                    title: "年费会员",
                    price: 35,
                    desc: "免费将公司放置在放舱网供应商目录",
                    tips: "赠送15天会员",
                    selected: false,
                    total: 420,
                },
                {
                    title: "永久会员",
                    price: 2100,
                    desc: "一辈子享受所有vip服务",
                    tips: "限时折扣",
                    selected: false,
                    total: 2100,
                },
            ],
        };
    },
    methods: {
        handleSwichVipItem(index) {
            this.curIndex = index;
            this.vipList = this.vipList.map((item, i) => {
                return {
                    ...item,
                    selected: i === index,
                };
            });
        },
    },
};
</script>
<style scoped lang="stylus">
/deep/ .el-card__header {
    background-image: linear-gradient(
        var(--RED-C11C20),
        var(--RED-79031D),
        var(--RED-68020F)
    );
}
.header {
    color: white;
    font-size: 18px;
    font-weight: 700;
}

.card-box{
    display: flex;
}


.card-tips{
    position: absolute;
    padding: 0 4px;
    height: 24px;
    line-height: 24px;
    background: -webkit-gradient(linear,left top,right top,from(#fc5531),to(#fc1944));
    background: linear-gradient(90deg,#fc5531,#fc1944);
    border-radius: 6px 0 8px 0;
    color: #fff;
    left: -2px;
    top: -12px;
}


.money-item{
    width: 204px;
    height: 134px;
    border-radius: 8px;
    border: 2px solid #fae8d0;
    background-color: #fff;
    position: relative;
    text-align: center;
    margin-right: 26px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    cursor: pointer;
}

.money-item-selected{
    border-color: #b87100;
    background-color: #fff9f1;
}

.vip-card-icon{
    position: absolute;
    right: -3px;
    bottom:0;
    width: 24px;
    height: 24px;
    z-index: 3;
}

.title-1{
    font-size: 16px;
    height: 22px;
    line-height: 22px;
    color: #222226;
    font-weight: 700;
    margin: 24px 0 12px;
    position: relative;
    z-index: 2;
}

.title-2{
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    color: #b87100;
    position: relative;
    z-index: 2;
}

.money-number{
    font-size: 24px;
    font-weight: 700;
}
.card-footer{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 32px;
    background-color: #fae8d0;
    font-size: 12px;
    line-height: 32px;
    color: #69421b;
    font-weight: 500;
    bottom: 0;
    border-radius: 0 0 6px 6px;
    overflow: hidden;
}

.vip-right-container{
    height:100px;
    margin-top:20px;
}

.vip-right{
    flex: 1;
    margin: 0 auto;
    border-radius: 8px;
    background: #f8f8f8;
    height: 100px;
    padding: 16px 8px;
}
.vip-right-header{
    font-size: 16px;
    line-height: 22px;
    color: #222226;
    font-weight: 700;
    margin-bottom: 8px;
}

.swiper-wrapper{
    display:flex;
    gap:20px;
}

.swiper-item{
    background: #fff;
    color:#222;
    border-radius: 8px;
    width:235px;
    height: 68px;
    display: flex;
}

.swiper-item-img{
    height:68px;
    transform:translateX(-20px);
}
.swiper-item-title{
    font-size: 14px;
    line-height: 20px;
    color: #222226;
    transform:translateX(-35px);
    padding-top:5px;
}

.swiper-item-desc{
    display: inline-block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 12px;
    font-weight: 400;
    color: #999aaa;
    line-height: 16px;
    height: 32px;
    transform:translateX(-35px);
}

.vip-payment-container{
    height: 150px;
    border-radius: 8px;
    background: #f8f8f8;
    margin-top:50px;
    display:flex;
    align-items:center;
}

.vip-payment-time{
    background: #fff;
    padding: 8px;
    border-radius: 8px;
    height:20px;
    font-size:14px;
    margin-left:20px;
    color:red
}
</style>
